import React from 'react';
import Seo from '../components/seo';
import asset from '../lib/asset';
import Layout from '../components/layout';

export default function () {
  return (
    <Layout>
      <Seo title="2013 Mensajes" />
      <h1>2013 Mensajes:</h1>
      <p>
        Abajo están los mensajes de los domingos del 2013, en una lista ordenada
        cronológicamente. Para DESCARGAR un mensaje haga CLIC DERECHO en enlace de
        descarga, y seleccione “Salvar destino como...” (Save Target As...) o “Salvar
        enlace como...” (Save Link como...), dependiendo del navegador que use. Para
        ESCUCHAR, sólo haga clic en el enlace “escuchar”. Para mayor ayudar, para escuchar
        y descargar archivos de audio, visite nuestra Página de Ayuda para Audio.{' '}
      </p>
      <h2>2013 Mensajes</h2>
      <table
        className="sermon_table"
        width="654"
        border="0"
        cellSpacing="0"
        cellPadding="0"
      >
        <tbody>
          <tr>
            <td width="59">23/02/13</td>
            <td width="123">Jason Henderson </td>
            <td width="397">Hacer Nula La Gracia de Dios</td>
            <td width="41">
              <div align="center">
                <a href={asset('mensajes/13/mp3/01_Hacer_Nula_Gracia.mp3')}>audio</a>
              </div>
            </td>
            <td width="34">
              <div align="center">
                <a href={asset('mensajes/13/pdf/130217_Hacer_Nula_Gracia.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>03/03/13</td>
            <td>Jason Henderson </td>
            <td>El Camino</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/02_El_Camino.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130303_El_Camino.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>28/04/13</td>
            <td>Jason Henderson </td>
            <td>Que se Amen los Unos a los Otros - parte 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/03_Que_Se_Amen_01.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130428 _Que_se_Amen_01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>05/05/13</td>
            <td>Jason Henderson </td>
            <td>Que se Amen los Unos a los Otros - parte 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/04_Que_Se_Amen_02.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130505 _Que_se_Amen_02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>09/06/13</td>
            <td>Jason Henderson </td>
            <td>La Despedida</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130609_La_Despedida.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>20/07/13</td>
            <td>Jason Henderson </td>
            <td>El Temor del Señor</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/05_Temor_del_Senor.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130720_Temor_del_Senor.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>10/08/13</td>
            <td>Jason Henderson </td>
            <td>El Desierto</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/06_El_Desierto.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130731_Desierto.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>11/08/13</td>
            <td>Keren Alvarado</td>
            <td>Invirtamos en lo Eterno</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130811_Keren_Invirtamos.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/08/13</td>
            <td>Elizabeth Montero</td>
            <td>Dos Cuadros</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/07_Dos_Cuadros.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130825_Dos_Cuadros.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>01/09/13</td>
            <td>Esteban Hernández</td>
            <td>El Propósito Eterno de Dios y Nuestro Propósito como Vasijas</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/08_Proposito_de_Dios.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130901_Proposito_de_Dios.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>08/09/13</td>
            <td>Grace Montero</td>
            <td>La Cruz de Cristo</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/09_La_Cruz_de_Cristo.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130908_La_Cruz_de_Cristo.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>21/09/13</td>
            <td>Jason Henderson</td>
            <td>La Relación del Creyente con el Pecado - parte 1</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/10_Pecado.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130914_Pecado_01.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>28/09/13</td>
            <td>Jason Henderson</td>
            <td>La Relación del Creyente con el Pecado - parte 2</td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/mp3/11_Pecado_2.mp3')}>audio</a>
              </div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130923_Pecado_02.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>22/09/13</td>
            <td>Elizabeth Montero</td>
            <td>La Cresta Divisoria - La Cruz</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130922_LaCrestaDivisoria.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>29/09/13</td>
            <td>Elizabeth Montero</td>
            <td>Novedad de Vida</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/130929_Novedad_de_Vida.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>18/10/13</td>
            <td>Jason Henderson</td>
            <td>El Propósito Eterno</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/131018_Proposito_Eterno.pdf')}>texto</a>
              </div>
            </td>
          </tr>
          <tr>
            <td>25/10/13</td>
            <td>Jason Henderson</td>
            <td>El Ámbito del Propósito</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/131025_Ambito_del_Proposito.pdf')}>
                  texto
                </a>
              </div>
            </td>
          </tr>
          <tr>
            <td>02/11/13</td>
            <td>Jason Henderson</td>
            <td>Israel Mi Hijo</td>
            <td>
              <div align="center"></div>
            </td>
            <td>
              <div align="center">
                <a href={asset('mensajes/13/pdf/131102_Israel_Mi_Hijo.pdf')}>texto</a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </Layout>
  );
}
